<template>
    <div>
        <div v-if="(form_type === 'delete' && field.is_visible_for_delete) || (form_type === 'view' && field.is_visible_for_view)"
            v-for="(field, index) in fields" class="form-group row">
            <label v-if="field.type !== 'repeater'" :for="field.name" class="col-md-3">{{ field.label }} {{
                field.nullable
                    ? '' : '*' }}</label>
            <div class="col-md-9" v-if="field.type !== 'repeater'">
                <a v-if="typeof field.value !== 'undefined' && field.type !== 'repeater' && field.type === 'file'"
                    :href="appendToken(field.value.href)" target="_blank"
                    class="text-dark-75 text-hover-primary font-size-lg">{{ field.value.name }}</a>

                <span
                    v-if="typeof field.value !== 'undefined' && field.type !== 'repeater' && field.type !== 'file' && (field.type !== 'text' || (field.type === 'text' && !field.rich))"
                    class="text-dark-75 text-hover-primary font-size-lg">{{ field.value }}</span>

                <span
                    v-if="typeof field.value !== 'undefined' && field.type !== 'repeater' && field.type !== 'file' && field.type === 'text' && field.rich"
                    class="text-dark-75 font-size-lg" v-html="field.value"></span>
            </div>

            <FieldRepeater v-if="field.type === 'repeater'" :form_type="form_type" :errors="errors"
                v-model="field.items" :field="field" :fields="field.fields" :field_prefix="field.name"
                v-on:remove-error="removeError" />
        </div>

        <div v-if="(form_type === 'new' && field.is_visible_for_store) || (form_type === 'edit' && field.is_visible_for_update) || (form_type === 'copy' && field.is_visible_for_copy)"
            v-for="(field, index) in fields" class="form-group">

            <slot :name="'before-field-' + field.name" v-bind:values="{ field: field, fields: fields, index: index }">
            </slot>

            <component v-if="field.before_component" :ref="'before-component-' + field.name"
                v-bind:is="field.before_component"></component>

            <label v-if="field.type !== 'repeater' && field.type !== 'hidden'"
                :for="(field_prefix_id ? field_prefix_id : '') + field.name + (field_suffix_id ? field_suffix_id : '')"
                :class="{ 'is-invalid': typeof errors[field.name] !== 'undefined' }">{{ field.label }} {{ field.nullable
                    ?
                    '' : '*' }}</label>

            <br v-if="field.type === 'datetime'">
            <date-picker v-if="field.type === 'datetime' && !field.readonly" v-model="field.value" type="datetime"
                placeholder="Seleziona una data" format="DD/MM/YYYY HH:mm:ss" time-title-format="DD/MM/YYYY"
                value-type="YYYY-MM-DD HH:mm:ss"
                :input-class="(typeof errors[field.name] !== 'undefined' ? 'mx-input is-invalid' : 'mx-input')"
                :shortcuts="date_shortcuts" @input="removeError(field.name)" :disabled="field.readnonly"></date-picker>
            <input
                :id="(field_prefix_id ? field_prefix_id : '') + field.name + (field_suffix_id ? field_suffix_id : '')"
                v-if="field.type === 'datetime' && field.format === 'datetime' && field.readonly"
                :class="{ 'is-invalid': typeof errors[field.name] !== 'undefined', 'border': typeof errors[field.name] === 'undefined', 'border-primary': typeof errors[field.name] === 'undefined' }"
                class="form-control form-control-solid" :placeholder="field.placeholder" v-model="field.value"
                @input="removeError(field.name)" :disabled="field.readonly" />

            <br v-if="field.type === 'date'">
            <date-picker v-if="field.type === 'date'" v-model="field.value" type="date" placeholder="Seleziona una data"
                format="DD/MM/YYYY" time-title-format="DD/MM/YYYY" value-type="YYYY-MM-DD"
                :input-class="(typeof errors[field.name] !== 'undefined' ? 'mx-input is-invalid' : 'mx-input')"
                :shortcuts="date_shortcuts"
                :disabled-date="typeof field.disable_date !== 'undefined' ? field.disable_date : disable_date"
                @input="removeError(field.name)" :disabled="field.readnonly"></date-picker>
            <input
                :id="(field_prefix_id ? field_prefix_id : '') + field.name + (field_suffix_id ? field_suffix_id : '')"
                v-if="field.type === 'date' && field.format === 'date' && field.readonly"
                :class="{ 'is-invalid': typeof errors[field.name] !== 'undefined', 'border': typeof errors[field.name] === 'undefined', 'border-primary': typeof errors[field.name] === 'undefined' }"
                class="form-control form-control-solid" :placeholder="field.placeholder" v-model="field.value"
                @input="removeError(field.name)" :disabled="field.readonly" />

            <input
                :id="(field_prefix_id ? field_prefix_id : '') + field.name + (field_suffix_id ? field_suffix_id : '')"
                v-if="field.type === 'integer' && !field.relation" type="text"
                :class="{ 'is-invalid': typeof errors[field.name] !== 'undefined', 'border': typeof errors[field.name] === 'undefined', 'border-primary': typeof errors[field.name] === 'undefined' }"
                class="form-control form-control-solid" :placeholder="field.placeholder" v-model="field.value"
                style="width: 200px;" maxlength="10" @input="removeError(field.name)" :disabled="field.readonly" />

            <currency-input
                :id="(field_prefix_id ? field_prefix_id : '') + field.name + (field_suffix_id ? field_suffix_id : '')"
                v-if="field.type === 'currency' && !field.readonly" type="text"
                :class="{ 'is-invalid': typeof errors[field.name] !== 'undefined', 'border': typeof errors[field.name] === 'undefined', 'border-primary': typeof errors[field.name] === 'undefined' }"
                class="form-control form-control-solid" :placeholder="field.placeholder" v-model="field.value"
                style="width: 200px;" maxlength="15" @input="removeError(field.name)"
                :options="{ currency: 'EUR', hideCurrencySymbolOnFocus: true, hideGroupingSeparatorOnFocus: true, hideNegligibleDecimalDigitsOnFocus: true }"
                :disabled="field.readonly" />
            <input
                :id="(field_prefix_id ? field_prefix_id : '') + field.name + (field_suffix_id ? field_suffix_id : '')"
                v-if="field.type === 'currency' && field.format === 'currency'" type="text"
                :class="{ 'is-invalid': typeof errors[field.name] !== 'undefined', 'border': typeof errors[field.name] === 'undefined', 'border-primary': typeof errors[field.name] === 'undefined' }"
                class="form-control form-control-solid" :placeholder="field.placeholder" v-model="field.value"
                :maxlength="field.size" style="width: 300px;" @input="removeError(field.name)"
                :disabled="field.readonly" />

            <input
                :id="(field_prefix_id ? field_prefix_id : '') + field.name + (field_suffix_id ? field_suffix_id : '')"
                v-if="(field.type === 'integer' || field.type === 'has_many') && field.relation && (field.relation_type === 'belongsTo' || field.relation_type === 'belongsToMany') && field.readonly"
                type="text"
                :class="{ 'is-invalid': typeof errors[field.name] !== 'undefined', 'border': typeof errors[field.name] === 'undefined', 'border-primary': typeof errors[field.name] === 'undefined' }"
                class="form-control form-control-solid" :placeholder="field.placeholder"
                :value="getSelectedValue(field)" :maxlength="field.size" :disabled="field.readonly" />

            <button
                v-if="field.type === 'has_many' && field.relation && field.relation_type === 'belongsToMany' && !field.readonly"
                class="btn btn-ssmm btn-primary ml-2" type="button" @click.prevent="selectAll(field)">Seleziona
                Tutti</button>
            <button
                v-if="field.type === 'has_many' && field.relation && field.relation_type === 'belongsToMany' && !field.readonly"
                class="btn btn-ssmm btn-primary ml-2" type="button" @click.prevent="deselectAll(field)">Deseleziona
                Tutti</button>
            <button
                v-if="field.type === 'integer' && field.relation && field.relation_type === 'belongsTo' && field.nullable"
                class="btn btn-ssmm btn-primary ml-2" type="button"
                @click.prevent="deselectAll(field)">Deseleziona</button>
            <multiselect style="width: 100%;"
                :id="(field_prefix_id ? field_prefix_id : '') + field.name + (field_suffix_id ? field_suffix_id : '')"
                v-if="(field.type === 'integer' || field.type === 'has_many') && field.relation && (field.relation_type === 'belongsTo' || field.relation_type === 'belongsToMany') && !field.readonly"
                v-model="field.value" :options="(field.filter_options === null ? [] : field.filter_options)"
                :multiple="(field.type === 'integer' ? false : true)"
                :close-on-select="(field.type === 'integer' ? true : false)" :clear-on-select="false"
                placeholder="Seleziona una opzione" :label="field.relation_fields" track-by="id"
                :allow-empty="(field.type === 'has_many' || (field.type === 'integer' && field.relation_type === 'belongsTo' && field.nullable)) ? true : false"
                selectLabel="" selectedLabel="" deselectLabel="" showLabels="" selectGroupLabel="" deselectGroupLabel=""
                :class="{ 'is-invalid': typeof errors[field.name] !== 'undefined' }"
                :group-values="field.option_group ? field.relation : null"
                :group-label="field.option_group ? field.option_group : null"
                :group-select="field.option_group ? true : null" @input="removeError(field.name)">
                <template v-slot:noOptions>
                    Nessuna opzione disponibile
                </template>
            </multiselect>

            <input
                :id="(field_prefix_id ? field_prefix_id : '') + field.name + (field_suffix_id ? field_suffix_id : '')"
                v-if="field.type === 'string' && field.format === 'email'" type="email"
                :class="{ 'is-invalid': typeof errors[field.name] !== 'undefined', 'border': typeof errors[field.name] === 'undefined', 'border-primary': typeof errors[field.name] === 'undefined' }"
                class="form-control form-control-solid" :placeholder="field.placeholder" v-model="field.value"
                @input="removeError(field.name)" :disabled="field.readonly" />

            <input
                :id="(field_prefix_id ? field_prefix_id : '') + field.name + (field_suffix_id ? field_suffix_id : '')"
                v-if="field.size >= 50 && field.type === 'string' && (field.format === 'text' || field.format === 'url')"
                type="text"
                :class="{ 'is-invalid': typeof errors[field.name] !== 'undefined', 'border': typeof errors[field.name] === 'undefined', 'border-primary': typeof errors[field.name] === 'undefined' }"
                class="form-control form-control-solid" :placeholder="field.placeholder" v-model="field.value"
                :maxlength="field.size" @input="removeError(field.name)" :disabled="field.readonly" />

            <input
                :id="(field_prefix_id ? field_prefix_id : '') + field.name + (field_suffix_id ? field_suffix_id : '')"
                v-if="field.size < 50 && field.type === 'string' && (field.format === 'text' || field.format === 'url')"
                type="text"
                :class="{ 'is-invalid': typeof errors[field.name] !== 'undefined', 'border': typeof errors[field.name] === 'undefined', 'border-primary': typeof errors[field.name] === 'undefined' }"
                class="form-control form-control-solid" :placeholder="field.placeholder" v-model="field.value"
                :maxlength="field.size" style="width: 300px;" @input="removeError(field.name)"
                :disabled="field.readonly" />

            <input
                :id="(field_prefix_id ? field_prefix_id : '') + field.name + (field_suffix_id ? field_suffix_id : '')"
                v-if="field.type === 'hidden'" type="hidden" v-model="field.value" @input="removeError(field.name)" />
            <h4 v-if="field.type === 'hidden' && field.message && (typeof field.format === 'undefined' || field.format !== 'html')"
                class="text-justify">{{ field.message }}</h4>
            <h4 v-if="field.type === 'hidden' && field.message && (typeof field.format !== 'undefined' && field.format === 'html')"
                v-html="field.message" />

            <br v-if="field.type === 'taxcode'"><input
                :id="(field_prefix_id ? field_prefix_id : '') + field.name + (field_suffix_id ? field_suffix_id : '')"
                v-if="field.type === 'taxcode'" type="text"
                :class="{ 'is-invalid': typeof errors[field.name] !== 'undefined', 'border': typeof errors[field.name] === 'undefined', 'border-primary': typeof errors[field.name] === 'undefined' }"
                class="form-control form-control-solid" :placeholder="field.placeholder" v-model="field.value"
                :maxlength="field.size" style="width: 200px; display: inline-block; margin-right: 5px;"
                @input="removeError(field.name)" :disabled="field.readonly" />
            <TaxCodeBtn v-if="field.type === 'taxcode' && !field.readonly"
                :modalid="(field_prefix_id ? field_prefix_id : '') + 'taxcode-' + field.name + (field_suffix_id ? field_suffix_id : '')"
                v-model="field.value"></TaxCodeBtn>

            <FileUpload v-if="field.type === 'file' && !field.readonly"
                :id="(field_prefix_id ? field_prefix_id : '') + 'file-' + field.name + (field_suffix_id ? field_suffix_id : '')"
                :name="(field_prefix_id ? field_prefix_id : '') + 'file-' + field.name + (field_suffix_id ? field_suffix_id : '')"
                :multiple="false" v-model="field.value"></FileUpload>

            <input
                :id="(field_prefix_id ? field_prefix_id : '') + field.name + (field_suffix_id ? field_suffix_id : '')"
                v-if="field.type === 'string' && field.format === 'password'" type="password"
                :class="{ 'is-invalid': typeof errors[field.name] !== 'undefined', 'border': typeof errors[field.name] === 'undefined', 'border-primary': typeof errors[field.name] === 'undefined' }"
                class="form-control form-control-solid" :placeholder="field.placeholder" v-model="field.value"
                :maxlength="field.size" style="width: 300px;" @input="removeError(field.name)"
                :disabled="field.readonly" />

            <input
                :id="(field_prefix_id ? field_prefix_id : '') + field.name + '_confirm' + (field_suffix_id ? field_suffix_id : '')"
                v-if="field.type === 'string' && field.format === 'password' && !field.readonly" type="password"
                :class="{ 'is-invalid': typeof errors[field.name] !== 'undefined', 'border': typeof errors[field.name] === 'undefined', 'border-primary': typeof errors[field.name] === 'undefined' }"
                class="form-control form-control-solid mt-2" placeholder="Conferma password"
                v-model="field.password_confirm" :maxlength="field.size" style="width: 300px;"
                @input="removeError(field.name)" />

            <input
                :id="(field_prefix_id ? field_prefix_id : '') + field.name + '_generated' + (field_suffix_id ? field_suffix_id : '')"
                v-if="field.type === 'string' && field.format === 'password' && !field.readonly" type="text"
                :class="{ 'is-invalid': typeof errors[field.name] !== 'undefined', 'border': typeof errors[field.name] === 'undefined', 'border-primary': typeof errors[field.name] === 'undefined' }"
                class="form-control form-control-solid mt-2 d-inline-block mr-2" placeholder="Genera Password"
                :maxlength="field.size" style="width: 300px;" @input="removeError(field.name)" />
            <button v-if="field.type === 'string' && field.format === 'password' && !field.readonly"
                class="btn btn-primary btn-sm"
                @click.prevent="generatePassword(field_prefix_id, field.name, field_suffix_id)">Genera Password</button>

            <select
                :id="(field_prefix_id ? field_prefix_id : '') + field.name + (field_suffix_id ? field_suffix_id : '')"
                v-if="field.type === 'choice' && !field.readonly"
                :class="{ 'is-invalid': typeof errors[field.name] !== 'undefined', 'border': typeof errors[field.name] === 'undefined', 'border-primary': typeof errors[field.name] === 'undefined' }"
                class="form-control form-control-solid" v-model="field.value" @input="removeError(field.name)">
                <option value="">Seleziona una opzione</option>
            </select>

            <textarea
                :id="(field_prefix_id ? field_prefix_id : '') + field.name + (field_suffix_id ? field_suffix_id : '')"
                v-if="field.type === 'text' && field.rich === false"
                :class="{ 'is-invalid': typeof errors[field.name] !== 'undefined', 'border': typeof errors[field.name] === 'undefined', 'border-primary': typeof errors[field.name] === 'undefined' }"
                class="form-control form-control-solid" rows="3" v-model="field.value" @input="removeError(field.name)"
                :disabled="field.readonly"></textarea>

            <ckeditor :editor="editor"
                :id="(field_prefix_id ? field_prefix_id : '') + field.name + (field_suffix_id ? field_suffix_id : '')"
                v-if="field.type === 'text' && field.rich === true"
                :class="{ 'is-invalid': typeof errors[field.name] !== 'undefined', 'border': typeof errors[field.name] === 'undefined', 'border-primary': typeof errors[field.name] === 'undefined' }"
                v-model="field.value" @input="removeError(field.name)" :disabled="field.readonly">
            </ckeditor>

            <b-form-radio-group v-if="field.type === 'boolean' && field.rich === false" v-model="field.value"
                :options="field.options" :state="!(typeof errors[field.name] !== 'undefined')"
                @input="removeError(field.name)" :disabled="field.readonly">
                <b-form-invalid-feedback :state="typeof errors[field.name] !== 'undefined'"
                    :style="(typeof errors[field.name] !== 'undefined' ? 'display: block;' : '')">
                    <div v-for="error in errors[field.name]">{{ error }}</div>
                </b-form-invalid-feedback>
            </b-form-radio-group>

            <span :id="(field_prefix_id ? field_prefix_id : '') + field.name + (field_suffix_id ? field_suffix_id : '')"
                v-if="field.type === 'boolean' && field.rich === true"
                class="switch switch-outline switch-icon switch-primary" @input="removeError(field.name)">
                <label>
                    <input v-model="field.value" type="checkbox" :disabled="field.readonly" />
                    <span></span>
                </label>
            </span>

            <FieldRepeater v-if="field.type === 'repeater'" :form_type="form_type" :errors="errors"
                v-model="field.items" :field="field" :fields="field.fields" :field_prefix="field.name"
                v-on:remove-error="removeError" />

            <component v-if="field.after_component" :ref="'after-component-' + field.name"
                v-bind:is="field.after_component">
            </component>

            <slot :name="'after-field-' + field.name" v-bind:values="{ field: field, fields: fields, index: index }">
            </slot>

            <template
                v-if="typeof errors[field.name] !== 'undefined' && (field.type !== 'boolean' && field.type !== 'repeater')">
                <div v-for="(error, index) in errors[field.name]" class="invalid-feedback">{{ error }}</div>
            </template>
        </div>
    </div>
</template>

<script>
import TaxCodeBtn from "@/view/components/TaxCodeBtn.vue";
import NewUserTechnicalButton from "@/view/components/NewUserTechnicalButton.vue";
import CurrencyInput from "@/view/components/CurrencyInput.vue";
import FileUpload from "@/view/components/FileUpload.vue";
import FieldRepeater from "@/view/components/FieldRepeater.vue";
import JwtService from "@/core/services/jwt.service";
import CKEditor from '@ckeditor/ckeditor5-vue2';
import CustomEditor from '/src/ckeditor5custom/build/ckeditor.js';

export default {
    name: 'FormFields',

    props: ['form_type', 'errors', 'value', 'field_prefix_id', 'field_suffix_id'],

    data() {
        return {
            editor: CustomEditor.Editor,

            fields: [],

            date_shortcuts: [
                {
                    text: 'Oggi',
                    onClick() {
                        const date = new Date();

                        const item = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

                        return item;
                    },
                },
                {
                    text: 'Ieri',
                    onClick() {
                        const date = new Date();

                        const item = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

                        item.setTime(item.getTime() - 3600 * 1000 * 24);

                        return item;
                    },
                },
                {
                    text: 'Ultima settimana',
                    onClick() {
                        const date = new Date();

                        const item = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

                        item.setTime(item.getTime() - 3600 * 1000 * 24 * 7);

                        return item;
                    },
                },
                {
                    text: 'Ultimo mese',
                    onClick() {
                        const date = new Date();

                        const item = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

                        item.setTime(item.getTime() - 3600 * 1000 * 24 * 30);

                        return item;
                    },
                },
                {
                    text: 'Ultimi 3 mesi',
                    onClick() {
                        const date = new Date();

                        const item = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

                        item.setTime(item.getTime() - 3600 * 1000 * 24 * 90);

                        return item;
                    },
                },
                {
                    text: 'Ultimi 6 mesi',
                    onClick() {
                        const date = new Date();

                        const item = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

                        item.setTime(item.getTime() - 3600 * 1000 * 24 * 180);

                        return item;
                    },
                },
                {
                    text: 'Ultimo anno',
                    onClick() {
                        const date = new Date();

                        const item = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

                        item.setTime(item.getTime() - 3600 * 1000 * 24 * 365);

                        return item;
                    },
                },
            ],

            CHARACTER_SETS: [
                [true, "Numbers", "0123456789"],
                [true, "Lowercase", "abcdefghijklmnopqrstuvwxyz"],
                [true, "Uppercase", "ABCDEFGHIJKLMNOPQRSTUVWXYZ"],
                [false, "ASCII symbols", "!#$%&*+-:;?@"],
                //[false, "ASCII symbols", "!\"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~"],
                //[false, "Space", " "],
            ],

            cryptoObject: null,
        };
    },

    created() {
        this.fields = this.value;

        this.initCrypto();
    },

    mounted() {
    },

    watch: {
        'value': {
            handler: function (newValue) {
                this.fields = newValue;
            },
            deep: true,
        },

        'fields': {
            handler: function (newValue) {
                for (let i in this.fields) {
                    if (this.fields[i].type === 'date') {
                        this.fields[i].disable_date = function (date) {
                            let passes = true;
                            let check = true;

                            if (typeof this.maxdate !== "undefined" && this.maxdate !== "") {
                                if (this.maxdate === "today") {
                                    check = true;
                                    if (date < new Date(new Date().setHours(0, 0, 0, 0))) {
                                        check = false;
                                    }
                                    passes = passes && check;
                                }

                                check = true;
                                if (date > new Date(new Date(this.maxdate).setHours(0, 0, 0, 0))) {
                                    check = false;
                                }
                                passes = passes && check;
                            }

                            if (typeof this.mindate !== "undefined" && this.mindate !== "") {
                                check = true;
                                if (date < new Date(new Date(this.mindate).setHours(0, 0, 0, 0))) {
                                    check = false;
                                }
                                passes = passes && check;
                            }

                            return !passes;
                        }.bind(this.fields[i]);
                    }
                }

                this.$emit('input', newValue);
            },
            deep: true,
        }
    },

    methods: {
        getSelectedValue(field) {
            let value = '';

            if (field.relation_type === 'belongsTo') {
                if (typeof field.filter_options !== 'undefined' && field.filter_options && field.filter_options.length && typeof field.value !== 'undefined' && field.value.id !== 'undefined') {
                    for (let i in field.filter_options) {
                        if (field.value.id === field.filter_options[i].id) {
                            value = field.filter_options[i][field.relation_fields];
                            break;
                        }
                    }
                }
            }

            return value;
        },

        disable_date(date) {
            return false;
        },

        removeError(fieldName) {
            if (typeof this.errors !== "undefined") {
                if (typeof this.errors[fieldName] !== "undefined") {
                    this.$delete(this.errors, fieldName);
                }
            }
            this.$emit('remove-error', fieldName);
        },

        passwordGenerate() {
            // Gather the character set
            var charsetStr = "";
            this.CHARACTER_SETS.forEach(function (entry, i) {
                charsetStr += entry[2];
            });
            charsetStr = charsetStr.replace(/ /, "\u00A0");  // Replace space with non-breaking space

            // Convert to array and remove duplicate characters
            var charset = [];
            for (var i = 0; i < charsetStr.length; i++) {
                var c = charsetStr.charCodeAt(i);
                var s = null;
                if (c < 0xD800 || c >= 0xE000)  // Regular UTF-16 character
                    s = charsetStr.charAt(i);
                else if (0xD800 <= c && c < 0xDC00) {  // High surrogate
                    if (i + 1 < charsetStr.length) {
                        var d = charsetStr.charCodeAt(i + 1);
                        if (0xDC00 <= d && d < 0xE000) {
                            // Valid character in supplementary plane
                            s = charsetStr.substr(i, 2);
                            i++;
                        }
                        // Else discard unpaired surrogate
                    }
                } else if (0xDC00 <= d && d < 0xE000)  // Low surrogate
                    i++;  // Discard unpaired surrogate
                else
                    throw "Assertion error";
                if (s != null && charset.indexOf(s) == -1)
                    charset.push(s);
            }

            var password = "";
            var statistics = "";
            if (charset.length == 0)
                console.log("Error: Character set is empty");
            else if (charset.length == 1)
                console.log("Error: Need at least 2 distinct characters in set");
            else {
                var length = 15;

                if (length < 0)
                    console.log("Negative password length");
                else if (length > 10000)
                    console.log("Password length too large");
                else {
                    for (var i = 0; i < length; i++)
                        password += charset[this.randomInt(charset.length)];
                }
            }

            return password;
        },

        // Returns a random integer in the range [0, n) using a variety of methods
        randomInt(n) {
            var x = this.randomIntMathRandom(n);
            x = (x + this.randomIntBrowserCrypto(n)) % n;
            return x;
        },

        // Not secure or high quality, but always available
        randomIntMathRandom(n) {
            var x = Math.floor(Math.random() * n);
            if (x < 0 || x >= n)
                throw "Arithmetic exception";
            return x;
        },

        // Uses a secure, unpredictable random number generator if available; otherwise returns 0
        randomIntBrowserCrypto(n) {
            if (this.cryptoObject == null)
                return 0;
            // Generate an unbiased sample
            var x = new Uint32Array(1);
            do this.cryptoObject.getRandomValues(x);
            while (x[0] - x[0] % n > 4294967296 - n);
            return x[0] % n;
        },

        initCrypto() {
            var textNode = document.createTextNode("\u2717");
            //document.getElementById("crypto-getrandomvalues-entropy").appendChild(textNode);

            if ("crypto" in window)
                this.cryptoObject = crypto;
            else if ("msCrypto" in window)
                this.cryptoObject = msCrypto;
            else
                return;

            if ("getRandomValues" in this.cryptoObject && "Uint32Array" in window && typeof Uint32Array == "function")
                textNode.data = "\u2713";
            else
                this.cryptoObject = null;
        },

        generatePassword(field_prefix_id, field_name, field_suffix_id) {
            let password = this.passwordGenerate();

            let field = this.fields.find((item) => {
                return item.name == field_name;
            });

            let index = this.fields.findIndex((item) => {
                return item.name == field_name;
            });

            if (typeof field !== "undefined" && field) {
                this.$set(this.fields[index], 'value', password);
                this.$set(this.fields[index], 'password_confirm', password);
                //field.value = password;
                //field.password_confirm = password;

                let id = (field_prefix_id ? field_prefix_id : '') + field_name + '_generated' + (field_suffix_id ? field_suffix_id : '');
                let el = document.getElementById(id);
                el.value = password;
            }
        },

        appendToken(url) {
            if (url) {
                let parts = url.split('?');
                if (parts.length >= 2) {
                    return url += '&token=' + JwtService.getToken();
                }
                else {
                    return url += '?token=' + JwtService.getToken();
                }
            }

            return url;
        },

        selectAll(field) {
            let options = field.filter_options === null ? [] : field.filter_options;
            let value = [];
            for (let i in options) {
                if (typeof field.option_group !== "undefined" && field.option_group) {
                    for (let j in options[i][field.relation]) {
                        value.push(JSON.parse(JSON.stringify(options[i][field.relation][j])));
                    }
                }
                else {
                    value.push(JSON.parse(JSON.stringify(options[i])));
                }
            }

            this.$set(field, 'value', value);
        },

        deselectAll(field) {
            this.$set(field, 'value', []);
        },
    },

    components: {
        TaxCodeBtn,
        NewUserTechnicalButton,
        FieldRepeater,
        FileUpload,
        CurrencyInput,
        ckeditor: CKEditor.component,
    },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.multiselect__option--highlight {
    background-color: #0066CC;
}

.multiselect__option--selected {
    background-color: #187DE4;
    color: #FFFFFF;
}

.multiselect__option--selected.multiselect__option--highlight {
    background-color: #8950FC;
    color: #FFFFFF;
}

.multiselect {
    width: 100px;
    background-color: #E1F0FF;
}

.multiselect__content-wrapper {
    background-color: #E1F0FF;
}

.multiselect__tags {
    background: transparent;
}

.multiselect__input,
.multiselect__single {
    background: transparent;
}

.multiselect__tag {
    background-color: #0066CC;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    border-color: #F64E60 !important;
    background-color: #F6AAB2 !important;
}

.multiselect {
    border: 1px solid #0066CC !important;
}

.multiselect.is-invalid {
    border: 1px solid #F64E60 !important;
    border-radius: 0.42rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F64E60' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F64E60' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.325rem + 0.75em + 0.65rem) center;
    background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}

.was-validated .custom-control-input:valid~.custom-control-label,
.custom-control-input.is-valid~.custom-control-label {
    color: #0066CC;
}

.was-validated .custom-control-input:valid~.custom-control-label::before,
.custom-control-input.is-valid~.custom-control-label::before {
    border-color: #0066CC;
}

.was-validated .custom-control-input:valid:checked~.custom-control-label::before,
.custom-control-input.is-valid:checked~.custom-control-label::before {
    border-color: #187DE4;
    background-color: #0066CC;
}

.checkbox.checkbox-outline.checkbox-primary>span {
    border-color: #0066CC;
}

.checkbox.checkbox-outline.checkbox-primary>input:focus~span {
    border-color: #0066CC;
}

input.mx-input {
    border-color: #0066CC;
}

input.mx-input.is-invalid {
    border-color: #F64E60 !important;
}

.mx-datepicker-main {
    font: inherit !important;
    color: inherit !important;
}

.mx-datepicker-sidebar {
    width: 160px;
}

.mx-datepicker-sidebar+.mx-datepicker-content {
    margin-left: 160px;
}

label.is-invalid {
    color: red;
}

input[type="text"]:disabled {
    background: #CCCCCC;
}

.multiselect__option.multiselect__option--group {
    font-weight: bolder;
}

.multiselect__option.multiselect__option--group.multiselect__option--highlight {
    font-weight: bolder;
    background-color: #8950FC;
    color: #FFFFFF;
}

.multiselect__option.multiselect__option--group.multiselect__option--group-selected {
    font-weight: bolder;
    background-color: #369a6e;
    color: #FFFFFF;
}

.multiselect__option.multiselect__option--group.multiselect__option--highlight.multiselect__option--group-selected {
    font-weight: bolder;
    background-color: #F64E60;
    color: #FFFFFF;
}

.btn-ssmm,
.btn-group-ssmm>.btn {
    padding: 0.45rem 0.65rem;
    font-size: 0.85rem;
    line-height: 1.0;
    border-radius: 0.32rem;
}
</style>
